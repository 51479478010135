
import { defineComponent, ref, computed, watchEffect, reactive } from 'vue'
import { ICourseItem, Paginator } from '@/service/course/types'
import { IPartnersItem } from '@/store/partners/types'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import Pagination from '@/base-ui/pagination'
import Empty from '@/components/empty'
import hhRequest from '@/service'

export default defineComponent({
  components: {
    Pagination,
    Empty
  },
  setup() {
    const list = ref<Paginator<IPartnersItem>>({
      current_page: 1,
      last_page: 1,
      per_page: 12,
      total: 0,
      data: []
    })
    const page = ref(1)
    const type = ref('COLLEGE')

    const getList = async () => {
      try {
        const params = {
          type: type.value,
          page: page.value
        }
        list.value = reactive<Paginator<IPartnersItem>>(
          await hhRequest.get<Paginator<IPartnersItem>>({
            url: '/network',
            params: params
          })
        )
      } catch (error) {
        return error
      }
    }

    const updatePage = (val: number) => {
      page.value = val
      getList()
    }

    const updateType = (val: string) => {
      page.value = 1
      type.value = val
      getList()
    }

    getList()

    return {
      type,
      list,
      page,
      updatePage,
      updateType
    }
  }
})
